$(document).ready(function(){

    var divMapBg = $('.map-mobile-container');
    var bgInit = divMapBg.css('background-image');
    $(".btn-map").click(function(){
        $(".map-content").toggleClass("map-active");
        if($(".map-content").hasClass("map-active")) {
            $(".btn-map p").text("Afficher la liste");
            divMapBg.css("background-image", "none");
        } else {
            $(".btn-map p").text("Afficher la carte");
            divMapBg.css("background-image", bgInit);
        }
    });

});