$(document).ready(function(){

    //Stick filters
    if (window.matchMedia("(min-width: 1024px)").matches) {
        $(window).scroll(function () {
            $("#form-filters-realestate").toggleClass("filters-shrink", $(this).scrollTop() > 263)
        });
        $(window).scroll(function () {
            $("#mainHeader").toggleClass("navbar-shoot", $(this).scrollTop() > 160)
        });
    }

});