import {Loader} from "@googlemaps/js-api-loader";

(function ($) {

    let map;
    let oms;
    let markers = [];
    let markersVisible = [];
    let markerCluster;

    let prevInfoWindow = false;

    const $form = $('#form-filters-realestate');
    let $cards = $('.card-realestate');


    const _loadMap = function (options) {
        const loader = new Loader({
            apiKey: "AIzaSyCSvrs0fQU2O745mxCRoPA3E4cRoxsReSc",
            version: "weekly",
        });
        loader
            .load()
            .then((google) => {
                map = new google.maps.Map(document.getElementById(options.map.elementId), {
                    center: new google.maps.LatLng(options.map.lat, options.map.lng),
                    zoom: options.map.zoom,
                    mapTypeId: options.map.mapTypeId
                });
                map.set('styles',
                    [
                        {
                            "featureType": "all",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "administrative",
                            "elementType": "labels.text.fill",
                            "stylers": [
                                {
                                    "color": "#444444"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#e6ebec"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "landscape",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 45
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "labels.icon",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                },
                                {
                                    "gamma": "1.67"
                                },
                                {
                                    "color": "#81b6f6"
                                },
                                {
                                    "saturation": "-34"
                                },
                                {
                                    "lightness": "32"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "visibility": "on"
                                },
                                {
                                    "hue": "#00ffeb"
                                },
                                {
                                    "saturation": "-39"
                                },
                                {
                                    "weight": "1.84"
                                }
                            ]
                        }
                    ],
                );
                oms = new OverlappingMarkerSpiderfier(map, {
                    markersWontMove: true,   // we promise not to move any markers, allowing optimizations
                    markersWontHide: true,   // we promise not to change visibility of any markers, allowing optimizations
                    basicFormatEvents: true,  // allow the library to skip calculating advanced formatting information
                    keepSpiderfied: true,
                });

                _initMarkers(options);
            })
            .catch(e => {
                console.error(e);
            });
    };

    const _initMarkers = function (options) {
        let $markers = $('.marker');
        // On charge les markers et l'infoWindow
        $markers.each(function () {
            _initMarker($(this), map);
        });
        _displayMarkers(options);
    };

    const _initMarker = function ($marker, map) {
        // Get position from marker.
        let lat = $marker.attr('data-lat');
        let lng = $marker.attr('data-lng');
        let title = $marker.attr('data-title');
        let logo = $marker.attr('data-marker-logo');
        let type = $marker.attr('data-type');

        let latLng = {
            lat: parseFloat(lat),
            lng: parseFloat(lng)
        };

        // Create marker instance.
        let marker = new google.maps.Marker({
            position: latLng,
            map: map,
            title: title,
            type: type,
            icon: logo,
            animation: google.maps.Animation.DROP,
            optimized: true
        });

        markersVisible.push(marker);

        // If marker contains HTML, add it to an infoWindow.
        if ($marker.data('html')) {

            // Create info window.
            let infowindow = new google.maps.InfoWindow({
                content: JSON.parse($marker.data('html'))
            });

            // Show info window when marker is clicked.
            google.maps.event.addListener(marker, 'spider_click', function () {
                if (prevInfoWindow) {
                    prevInfoWindow.close();
                }
                prevInfoWindow = infowindow;
                infowindow.open(map, marker);
            });
            oms.addMarker(marker);  // adds the marker to the spiderfier _and_ the map
        }
    };

    const _displayMarkers = function (options) {
        const data = _getFormData();

        markers = [];
        markersVisible.forEach((marker) => {
            marker.setVisible(false);
            if (data.length > 0) {
                if (data.includes(marker.type)) {
                    marker.setVisible(true);
                }
            } else {
                marker.setVisible(true);
            }

            if (true === marker.visible) {
                markers.push(marker);
            }
        });
        _displayCluster(options);
    };

    const _displayCluster = function (options) {
        if (markerCluster) {
            markerCluster.clearMarkers();
        }

        const renderer = {
            render({count, position}) {
                return new google.maps.Marker({
                    label: {text: String(count), color: "white", fontSize: "12px"},
                    position,
                    icon: options.map.clusterLogo,
                    // adjust zIndex to be above other markers
                    zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
                })
            }
        };

        import(/* webpackPrefetch: true */ '@googlemaps/markerclusterer').then(({MarkerClusterer}) => {
            markerCluster = new MarkerClusterer({markers: markers, map: map, renderer});
            google.maps.event.addListener(markerCluster, 'click', function (cluster) {
                map.setOptions({ maxZoom: 17 });
            });
        });

    };

    const _handleResponsive = function (options) {
        $('.btn-show-map').on('click', function () {
            let $this = $(this);
            let $map = $('#' + options.map.elementId);
            $map.parent().toggleClass('active');
            $map.toggleClass('active');
            if ($map.hasClass('active')) {
                $this.find('span').text('Cacher la carte');
            } else {
                $this.find('span').text('Afficher la carte');
            }
        });
    };

    const _handleForm = function (options) {
        $('.checkbox-housing').on('change', function () {
            let $this = $(this);
            if ($this.is(':checked')) {
                $this.closest('li').addClass('active');
                $this.closest('label').find('.icon').removeClass('icon-marker-unactive').addClass('icon-marker');
            } else {
                $this.closest('li').removeClass('active');
                $this.closest('label').find('.icon').removeClass('icon-marker').addClass('icon-marker-unactive');
            }


            _displayCards();
            _displayMarkers(options);
        });
    };

    const _displayCards = function () {
        const data = _getFormData();
        $cards.each(function () {
            let $this = $(this);
            $this.show();

            if (data.length > 0) {
                if (!data.includes($this.data('type'))) {
                    $this.hide();
                }
            }
        });

        let $cardsVisible = $('.card-realestate:visible');
        $('.properties-number').text(`${$cardsVisible.length} bien${$cardsVisible.length > 1 ? 's' : ''}`);
    };

    const _getFormData = function () {
        return $form.serializeArray().map(val => val.value);
    };

    const _init = function (options) {
        _loadMap(options);
        _handleForm(options);
        _handleResponsive(options);
    };

    $(document).ready(function () {
        let options = {
            map: {
                elementId: 'acf-map',
                lat: 47.472699,
                lng: -0.557982,
                zoom: 9,
                mapTypeId: 'roadmap',
            },
        };

        // Listen to axeptio cookie if GMAPS in enabled
        void 0 === window._axcb && (window._axcb = []);
        window._axcb.push(function (axeptio) {
            const button = document.getElementById('openConsentAxeptio');
            if (!axeptio.hasAcceptedVendor('gmaps')) {
                button.style.display = "flex";
            }

            button.addEventListener('click', function (event) {
                axeptio.openCookies();
            });

            axeptio.on("cookies:complete", function (choices) {
                if ('gmaps' in choices && choices.gmaps === true) {
                    _init(options);
                } else {
                    button.style.display = "flex";
                }
            });
        });
    });
})(jQuery);
